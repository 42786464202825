import React from 'react';
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { navigate } from "gatsby"

function ContactForm() {
    const { register, handleSubmit } = useForm()
    const GATEWAY_URL = process.env.GATSBY_EMAIL_GATEWAY_URL

    const onSubmit = data => {
      fetch(GATEWAY_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data === "Email sent") {
            navigate("/thankyou")
          }
        })
        .catch(error => console.error("Error:", error))
    }

    const formBorderStyle = { borderRadius: 0 }

    return <Form className="mx-4 my-5" method="post" onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
      <Form.Label className="head-text">Naam (verplicht)</Form.Label>
      <Form.Control id="name" name="name" ref={register} style={formBorderStyle} type="text" required/>
    </Form.Group>

    <Form.Group>
      <Form.Label className="head-text">E-mailadres (verplicht)</Form.Label>
      <Form.Control id="email" name="email" ref={register} style={formBorderStyle} type="email" required/>
    </Form.Group>

    <Form.Group>
      <Form.Label className="head-text">Onderwerp</Form.Label>
      <Form.Control id="subject" name="subject" ref={register} style={formBorderStyle} type="text" required/>
    </Form.Group>

    <Form.Group>
      <Form.Label className="head-text">Bericht</Form.Label>
      <Form.Control id="message" name="message" ref={register} as="textarea" rows="5" style={formBorderStyle} type="text" required/>
    </Form.Group>

    <Button className="head-text mt-3" style={{backgroundColor: "#142D56", borderRadius: 0}} type="submit">
      VERZENDEN
    </Button>
  </Form>
}
export default ContactForm;